<template>
  <div class="programs">
    <main-app-bar>
      <template v-slot:title> Programs </template>

      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn :to="{ name: 'completed.programs' }" depressed replace>
            Current Programs
          </v-btn>

          <v-btn :to="{ name: 'draft.programs' }" depressed replace>
            Draft Programs
          </v-btn>

          <v-btn :to="{ name: 'archived.programs' }" depressed replace>
            Archived Programs
          </v-btn>
        </div>

        <v-btn
          class="bg-primary-gradient primary ml-auto"
          @click="$router.push({ name: 'new.program' })"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Program
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-12 mb-10">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ProgramsPage',

  components: {
    MainAppBar,
  },

  data() {
    return {
      addNew: false,
      icons: {
        add: mdiPlus,
      },
    }
  },
}
</script>
